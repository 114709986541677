
import { Component, Vue } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import Editor from '@/components/editor.vue'
import { apiDiscountLists, apiRedpacketCategoryLists, apiRedpacketGoodsAdd, apiRedpacketGoodsDetail, apiRedpacketGoodsEdit } from '@/api/application/redpacket_mall'
@Component({
  components: {
    MaterialSelect,
    Editor
  }
})
export default class RedpacketGoodsEdit extends Vue {
    /** S Data **/
    loading = false
    identity: number | null = null
    cateList: [] = []
    freightList: [] = []
    discountList: [] = []
    houseList: [] = []
    selectHouse: [] = []
    exchangeType = [
      {
        icon: 'icon_goods',
        label: '商品',
        value: 1
      }
      // {
      //     icon: 'icon_xycj_cj',
      //     label: '红包',
      //     value: 2
      // }
    ]

    // 添加客服表单数据
    formData = {
      type: 1, // 兑换类型； 1商品 2-红包
      name: '', // 商品名称
      code: '', // 商品编号
      image: '', // 商品封面
      market_price: '', // 市场价
      stock: '', // 发放库存
      set_item_stock: [{
        name: '',
        stock: 0
      }],
      exchange_way: 2, // 兑换方式 1-红包 2-红包加余额；
      need_integral: '', // 所需红包
      need_money: '', // 所需金额
      delivery_way: 1, // 配送方式 0-无需物流 1-快递 (类型为商品时必填)
      express_type: 1, // 运费类型：1-包邮,2-统一运费 (配送方式为快递时必填)
      express_money: '', // 统一运费金额 （运费类型为统一运费时必填）
      express_template_id: '',
      sort: 0, // 排序
      status: 1, // 商品状态
      content: '', // 商品内容详情
      balance: '', // 红包金额
      category_id: 0,
      cateList: [],
      volume: '',
      weight: '',
      discount: '',
      warehouse: []
    }

  cate = {
    lists: []

  }

  // 表单校验
    rules = {
      name: [
        {
          required: true,
          message: '请输入商品名称',
          trigger: ['blur', 'change']
        }
      ],
      image: [
        {
          required: true,
          message: '请输入选择商品封面',
          trigger: ['blur', 'change']
        }
      ],
      stock: [
        {
          required: true,
          message: '请输入发放库存',
          trigger: ['blur', 'change']
        }
      ],
      need_integral: [
        {
          required: true,
          message: '请输入兑换红包',
          trigger: ['blur', 'change']
        }
      ],
      need_money: [
        {
          required: true,
          message: '请输入兑换金额',
          trigger: ['blur', 'change']
        }
      ],
      balance: [
        {
          required: true,
          message: '请输入红包面值',
          trigger: ['blur', 'change']
        }
      ],
      express_money: [
        {
          required: true,
          message: '请输入运费',
          trigger: ['blur', 'change']
        }
      ],
      express_template_id: [
        {
          required: true,
          message: '请选择运费模板',
          trigger: ['blur', 'change']
        }
      ]
    }

    /**规格操作 **/
    handleSpecAdd() {
      this.formData.set_item_stock.push({
        name: '',
        stock: 0
      })
    }

    handleSPecDel(index: number) {
      if (this.formData.set_item_stock.length <= 1) return this.$message.error('最后一条不允许删除')
      this.formData.set_item_stock.splice(index, 1)
    }

    /** E Data **/
    get pageTitle () {
      if (this.identity) {
        return '编辑红包商品'
      }
      return '新增红包商品'
    }

    get cateListArr () {
      return this.cate.lists || []
    }

    // 点击表单提交
    onSubmit () {
      // 验证表单格式是否正确
      const refs = this.$refs.formRef as HTMLFormElement
      refs.validate((valid: boolean): any => {
        if (!valid) {
          return
        }

        // 设置仓库
        this.formData.warehouse = this.selectHouse

        // 请求发送
        const request = this.identity
          ? apiRedpacketGoodsEdit({ id: this.identity, ...this.formData })
          : apiRedpacketGoodsAdd(this.formData)
        request.then(data => {
          setTimeout(() => {
            this.$router.back()
          }, 1000)
        })
      })
    }

    // 获取折扣列表
    getDiscountLists () {
      apiDiscountLists({}).then(data => {
        this.discountList = data.discount
        this.houseList = data.warehouse
      })
    }

    // 获取详情
    getDetails () {
      this.loading = true
      apiRedpacketGoodsDetail({
        id: this.identity as number
      })
        .then(res => {
          // 设置运费模版
          this.freightList = res.freightList
          this.selectHouse = res.selectList
          // 设置红包详情
          Object.keys(this.formData).map(key => {
            this.$set(this.formData, key, res[key])
          })
        })
        .finally(() => {
          this.loading = false
        })
    }

    getcateLists () {
      apiRedpacketCategoryLists({
        page: 1
      })
        .then(res => {
          Object.keys(this.cate).map(key => {
            this.$set(this.cate, key, res[key])
          })
        })
        .finally(() => {

        })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created () {
      this.getcateLists()
      this.getDiscountLists()
      const query: any = this.$route.query
      this.identity = query.id
      if (!this.identity) {
        return
      }
      this.getDetails()
    }
    /** E Life Cycle **/
}
